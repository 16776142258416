<template>
  <div>
    <!--<Slider></Slider>-->

    <div class="body px-2">

      <section class="d-none">
        <div class="form-wrapper mb-0">
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text text-blue">Phone</span>
            </div>
            <input type="text" name="amount" id="msisdn" class="form-control" placeholder="07XXXXXXXX" aria-label="Amount (to the nearest dollar)" v-model="msisdn1">
            <div class="input-group-append">
              <span class="input-group-text"></span>
            </div>
          </div>

          <div class="input-group mb-0">
            <div class="input-group-prepend">
              <span class="input-group-text text-blue">Ksh</span>
            </div>
            <input type="text" name="amount" id="deposit" class="form-control" value="99" aria-label="Amount (to the nearest dollar)" v-model="amount">
            <div class="input-group-append">
              <span class="input-group-text"></span>
            </div>
          </div>
          <small class="text-blue mt-3  mb-3">Minimum Deposit amount: Ksh 1</small>

          <button @click="deposit" v-bind:class="loading" class="join-button py-2 form-control" style="color: black !important;">
            DEPOSIT
          </button>

        </div>
      </section>
      <section class="">

        <!--
        <div class="pills-wrapper mb-3">
          <ul class="nav nav-pills nav-fill">

            <li class="nav-item">
              <a :class="['nav-link', activetab === '1' ? 'active' : '']" href="#" v-on:click="activetab='1'">Online
                Deposit</a>
            </li>
            <li class="nav-item">
              <a :class="['nav-link', activetab === '2' ? 'active' : '']" href="#" v-on:click="activetab='2'">Paybill</a>
            </li>

          </ul>
        </div>
        -->

        <div>
          <div class="text-center deposit-text-wrapper mb-3 d-flex justify-content-between">
            <div>
              Deposit From:
            </div>
            <div>
              <img loading="lazy" src="img/other/mpesa.png" class="m-auto mpesa-img-depo">
              <span class="phone-text-deposit text-light">{{ msisdnHidden }}</span>
            </div>
          </div>
          <div class="deposit-cards-wrapper row p-0 m-0 mb-4">

            <div class="col-4 pr-0 mb-2 pl-0" @click="setAmount(50)">
              <div :class="['deposit-card', amount == 50 ? 'active' : '']">
                <!-- <div class="bonus-section">
                        <span class="bonus-wrapper">
                            Ksh50 Bonus
                        </span>
                </div> -->
                <div class="amount-section text-center pt-1">
                  50
                </div>
                <div class="exp-section text-center">
                  Pay 50
                </div>
              </div>

            </div>
            <div class="col-4  pr-0 mb-2 " @click="setAmount(100)">
              <div :class="['deposit-card', amount == 100 ? 'active' : '']">
                <!-- <div class="bonus-section">
                        <span class="bonus-wrapper">
                            Ksh 100 Bonus
                        </span>
                </div> -->
                <div class="amount-section text-center pt-1">
                  100
                </div>
                <div class="exp-section text-center">
                  Pay 100
                </div>
              </div>

            </div>
            <div class="col-4 pr-0 mb-2  " @click="setAmount(200)">
              <div :class="['deposit-card', amount == 200 ? 'active' : '']">
                <!-- <div class="bonus-section">
                        <span class="bonus-wrapper">
                            Ksh 200 Bonus
                        </span>
                </div> -->
                <div class="amount-section text-center pt-1">
                  200
                </div>
                <div class="exp-section text-center">
                  Pay 200
                </div>
              </div>

            </div>
            <div class="col-4  pr-0 mb-2 pl-0 " @click="setAmount(500)">
              <div :class="['deposit-card', amount == 500 ? 'active' : ''] ">
                <!-- <div class="bonus-section">
                        <span class="bonus-wrapper">
                            Ksh 500 Bonus
                        </span>
                </div> -->
                <div class="amount-section text-center pt-1">
                  500
                </div>
                <div class="exp-section text-center">
                  Pay 500
                </div>
              </div>

            </div>
            <div class="col-4 pr-0 mb-2  " @click="setAmount(1000)">
              <div :class="['deposit-card', amount == 1000 ? 'active' : ''] ">
                <!-- <div class="bonus-section">
                        <span class="bonus-wrapper">
                            Ksh 1000 Bonus
                        </span>
                </div> -->
                <div class="amount-section text-center pt-1">
                  1000
                </div>
                <div class="exp-section text-center">
                  Pay 1000
                </div>
              </div>

            </div>
            <div class="col-4 pr-0 mb-2  ">
              <div id="manualDepoCard" :class="['deposit-card p-2', active == true ? 'active' : '']">
                <!-- <div class="bonus-section">
                        <span v-show="inputAmount > 0" class="bonus-wrapper">
                            Ksh {{ amount }} Bonus
                        </span>
                </div> -->
                <form>
                  <input id="manualDepo" type="tel" placeholder="Enter Amount" @focus="changeColor" @input="enteredValue($event)" v-on:blur="handleBlur" v-on:focus="addActiveClass">
                </form>
              </div>

            </div>
          </div>
          <div @click="setAmountA(amount)">
            <button class="form-control paybutton py-2 active">
              <span class="text-light">Pay {{ amount }}</span>
            </button>
          </div>
        </div>
        <div class="offers-section" v-show="activetab === '3' ">
          <div class="text-light text-center mb-1">
            Paybill Number
          </div>
          <div class="text-center">
            <img loading="lazy" src="img/other/mpesa.png" class="m-auto mpesa-img">
          </div>
          <div class="paybill-number text-center mb-4">
            1000
          </div>

          <div class="offer-subsection p-3 text-sub-light mb-3">
            <div class="text-center mb-3">
              Exclusive Offers
            </div>

            <ol style="padding-left: 15px">
              <li class="mb-2">
                Only Pay <strong class="text-light"> KES 50</strong> to make a successful deposit of
                <strong class="text-light">KES 100</strong>
              </li>
              <li class="mb-2">
                Only Pay <strong class="text-light"> KES 100</strong> to make a successful deposit of
                <strong class="text-light">KES 200</strong>
              </li>
              <li class="mb-2">
                Only Pay <strong class="text-light"> KES 200</strong> to make a successful deposit of
                <strong class="text-light">KES 400</strong>
              </li>
              <li class="mb-2">
                Only Pay <strong class="text-light"> KES 477</strong> to make a successful deposit of
                <strong class="text-light">KES 500</strong>
              </li>
              <li class="mb-2">
                Only Pay <strong class="text-light"> KES 1000</strong> to make a successful deposit of
                <strong class="text-light">KES 2000</strong>
              </li>
            </ol>
          </div>

          <div class="step-guide text-light">
            <div class="step-guide-title mb-2">
              Step Guide
            </div>
            <ol class="step-guide-list" style="display: none">
              <li class="mb-2">
                Go to M-PESA Menu
              </li>
              <li class="mb-2">
                Select Lipa na Mpesa
              </li>
              <li class="mb-2">
                Click on Paybill
              </li>
              <li class="mb-2">
                Enter Business Number
              </li>
              <li class="mb-2">
                Enter the account number as *<strong>Your Number</strong>*
              </li>
              <li class="mb-2">
                Enter the amount you want to deposit
              </li>
              <li class="mb-2">
                Enter your MPESA PIN and confirm the request
              </li>
              <li class="mb-2">
                You will receive a confirmation messag from MPESA to confirm the transaction
              </li>


            </ol>
          </div>

        </div>
      </section>

    </div>

  </div>
</template>

<script>

/*import Slider from './Slider'*/

import wallet from "@/services/wallet";
import Clarity from "@microsoft/clarity";

export default {
  name: 'Deposit',
  components: {
    /*Slider*/
  },
  data: function () {
    return {
      msisdn1: '',
      amount: '100',
      amountA: '',
      loading: '',
      activetab: '1',
      active: false,
      inputAmount: 0,

    }
  },
  watch: {
    amount: function () {
      if (this.amount == 50) {
        this.amount = 50;
      } else if (this.amount == 100) {
        this.amount = 100;
      } else if (this.amount == 200) {
        this.amount = 200;
      } else if (this.amount == 500) {
        this.amount = 500;
      } else if (this.amount == 1000) {
        this.amount = 1000;
      }
    }
  },
  computed: {

    bonus: function () {
      let value = (typeof (this.amount) == "string" && this.amount == '') ? '' : parseInt(this.amount);

      return value * 2;
    },
    profile: function() {

      return this.$store.state.profile;

    },

    msisdnHidden: function () {

      let msisdn = this.profile.msisdn.toString();
      return "****" + msisdn.slice(msisdn.length - 4)

    }
  },
  mounted() {
    var vm = this;
    this.$store.dispatch("setCurrentPage", "deposit");

    if (!vm.profile) {

      this.setError("Login", "Please login to proceed");
      this.$router.push({name: 'login', params: {action: 'login'}});
      return;

    }
    this.reloadProfile();
  },
  methods: {
    changeColor: function () {
      if(this.inputAmount > 0 ) {

        this.active = true;

      }
    },
    handleBlur: function () {

      if(this.inputAmount > 0 ) {

        var value = document.getElementById("manualDepo").value;

        if (value === "" || parseInt(value) === 0) {

          document.getElementById("manualDepoCard").classList.remove("active");

        }

      }


    },
    addActiveClass: function () {
      document.getElementById("manualDepoCard").classList.add("active");
    },
    enteredValue: function (event) {
      this.amount = event.target.value;
      this.inputAmount = this.amount
      this.active = true;
    },
    setAmount: function (amount) {

      this.inputAmount = 0;
      this.amount = amount;
      //this.deposit();

    },
    setAmountA: function (amount) {

      this.amount = amount;
      this.deposit();
    },
    deposit: function () {

      this.reset();
      var p = this.getProfile();

      if (!p) {

        this.setError("Login", "Please login to proceed");
        this.$router.push({name: 'login', params: {action: 'login'}});
        return;

      }

      if (this.amount < 1) {

        this.setError("Invalid Amount", "Enter amount atleast 1 KSH or above");
        return;

      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      vm.loading = 'loading';

      wallet.post(path, JSON.stringify(data), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            console.log(JSON.stringify(res.data))
            var msg = res.data.data;
            vm.setSuccess("Deposit Initiated", msg);
            Clarity.event("customer-deposit-request");

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              if (parseInt(err.response.status) === 401 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                vm.setError("Failed", err.response.data.error_message)
                console.log(JSON.stringify(err.response.data.message));
              }

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })
    }

  }
}
</script>

<style scoped>

body {
  font-size: 18px !important;
  padding: 10px;
  border-left: 1px solid rgba(255, 255, 255, .125);
  border-right: 1px solid rgba(255, 255, 255, .125);
}

.pills-wrapper {
  border-radius: 24px;
  overflow: hidden;
  background-color: #23313d;
}

.nav-pills .nav-item .active {
  border-radius: 24px;
}

.nav-pills .nav-link {
  color: #fff;
}

.nav-pills .nav-link.active {
  background-color: #40ab2a;
}

.phone-text-deposit {
  font-weight: 600;
}

.deposit-text-wrapper {
  padding: 10px;
  font-size: 15px;
  border-radius: 8px;
  color: rgba(255, 255, 255, .8);
  border: 1px solid var(--borderColor);
}

.bonus-section {
  font-size: 10px;
  color: #000;
  padding: 1px 4px;
  background-color: #FAE303;
  border-bottom-right-radius: 6px;
  width: fit-content;
  font-weight: 500;
  top: -2px;
}

.deposit-card {
  border-radius: 8px;
  border: 1px solid #fff;
  overflow: hidden;
  padding-bottom: 15px;
  height: 100%;
}

.deposit-cards-wrapper .amount-section {
  font-weight: 600;
  color: #fff;
}

.deposit-cards-wrapper .exp-section {
  font-size: 13px;
  color: rgba(255, 255, 255, .6);
}

.deposit-card input {
  border: 0px !important;
  color: #fff;
  text-align: center;
  width: 100%;
  background-color: transparent;
  line-height: 3.4;
  margin-top: 0px;
  font-size: 20px;
  font-weight: bold;
}

.deposit-card.active {
  background-color: #40ab2a;
  border: 1px solid #40ab2a;
}

.active .amount-section {
  color: #FFF;
}

input:focus {
  border: 0px !important;
  outline: transparent;
}

input::placeholder {
  color: #ffffff !important;
  font-size: 13px;
}

.paybutton {
  background-color: #23313d;
  font-weight: 400;
  color: rgba(255, 255, 255, .6);
  border-radius: 24px;
  border: 1px solid #23313d;
  font-size: 24px;
}

.paybutton.active {
  background-color: #40ab2a;
  font-weight: 400;
  color: #fff;
  border-radius: 24px;
  font-size: 24px;
  /*border: 1px solid #23313d;*/
}

.mpesa-img {
  height: 25px;
}

.mpesa-img-depo {
  height: 23px;
}

.paybill-number {
  font-weight: 600;
  color: rgba(255, 255, 255, .8);
  font-size: 32px;
}

.offer-subsection {
  border: 2px dotted var(--borderColor);
}

.text-sub-light {
  color: rgba(255, 255, 255, .75);
}

.offer-subsection li {

}

.modal-content {
  /*min-height: 50vh;*/
  /*min-width: 70%;*/
  background-color: transparent;
  background-image: url("/img/other/fancy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0px;
  padding: 20px;
}

.modal-dialog {
  min-width: 50%;


}

.modal-head {
  background-color: transparent;
}

.modal-body {
  background-color: transparent;
}

.modal-head {
  font-size: 26px;
}

.karibu-text {
  font-weight: 400;
  font-size: 20px;
}

.f-depo {
  font-size: 13px;
  color: rgba(0, 0, 0, .85);
}

.depo-btn {
  background-color: #40ab2a;
  border-radius: 0px;
}

.step-guide-title {
  font-weight: 500;
}

.step-guide-list {
  font-size: 14px;
  padding-left: 15px;
}

.reg-svg {
  height: 28px;
  margin-bottom: 5px;
}
</style>
<template>
  <div class="wrapper">

    <div class="body" style="padding:0;">

      <Slider></Slider>

      <div class="cas-header-wrapper">

        <div class="se-wrapper py-2 px-2">

          <div class="input-group mb-0">

            <input type="text" class="form-control" id="search-input" name="keyword"
              placeholder="Search for casino games" v-model="to_search"
              style="background-color:white; color: black; font-size:unset;">
            <div class="input-group-append cursor-pointer">

              <span @click="performSearch" v-show="searchgamesdata.length === 0" class="input-group-text text-light"
                style="background-color: var(--buttonBackgroundColor); border: none;"><i class="bi bi-search mr-1"></i>
                Search
              </span>

              <span @click="reset" v-show="searchgamesdata.length > 0" class="input-group-text text-light"
                style="background-color: var(--buttonBackgroundColor); border: none;">
                Reset
              </span>

            </div>

          </div>

        </div>

        <div v-show="searchgamesdata.length === 0" class="catsec pb-3 pt-1" id="catsec">

          <span v-for="(item, index) in categories" class="cattit" @click="getCasinoGames(item)" :class="isActive(item)"
            v-bind:key="getKey(index)">
            {{ item.name }}
          </span>

        </div>

      </div>

      <ShimmerCasino v-if="loading"></ShimmerCasino>

      <div class="row m-0 p-0" style="background-color: #1c2243">

        <div v-show="searchgamesdata.length > 0" class="casino-headers " style="">
          <span>Search Results</span>
        </div>

      </div>

      <div id="scroller">

        <div class="row m-0 p-0 pb-5" style="background-color: var(--gray-white)">

          <div style="text-align: center;" v-for="(i, x) in allgamesdata" v-bind:key="getKey(x)"
            class="casino-game col-lg-3 col-md-3 col-sm-3 col-4 m-0 p-1">

            <div data-toggle="modal" :data-target="'#casinoGames' + i.game_id">
              <v-lazy-image class="game-icon" :src-placeholder="placholder" :src="getImg(i.image)" alt="Avatar" />
            </div>

            <div class="game-name">
              <span style="font-weight: bolder" v-text="getName(i.game_name)"></span>
            </div>

            <div class="modal fade" :id="'casinoGames' + i.game_id" tabindex="-1" role="dialog"
              :aria-labelledby="'casinoGamesLabel' + i.game_id" aria-hidden="true">
              <div class="modal-dialog modal-dialog" role="document">
                <div style="color: var(--buttonBackgroundColor);" class="modal-content">

                  <div class="modal-header">
                    <h5 class="modal-title" :id="'casinoGamesLabel' + i.game_id">{{ i.game_name }}</h5>
                    <span style="color: var(--buttonBackgroundColor);" type="button" class="close" id="faso-close"
                      data-dismiss="modal">&times;</span>
                  </div>

                  <div class="modal-body">

                    <v-lazy-image :src-placeholder="placholder"
                      style="border-radius: 5px;border: solid var(--light-gray) 1px;" :src="i.image" alt="gameimg"
                      class="ww-100 mb-2" />

                    <div class="text-center">
                      <router-link data-dismiss="modal"
                        style="background-color: var(--buttonBackgroundColor);color: var(--darkBackground);"
                        class="btn ww-100 mb-2"
                        :to="{ name: 'casinogames', params: { IsDemo: 1, providerID: i.provider_id, gameName: i.game_name, gameID: i.game_id }, }">
                        Demo
                      </router-link>

                      <router-link data-dismiss="modal"
                        style="background-color: var(--safaricomGreen);color: var(--white);font-weight: bolder"
                        class="btn ww-100 mb-2"
                        :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: i.provider_id, gameName: i.game_name, gameID: i.game_id }, }">
                        Play Now
                      </router-link>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>
</template>
<style scoped>
.modal-content {
  background-color: #1B1E25;
}

.modal-body {
  background-color: #1B1E25;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.category-tabs {
  max-width: calc(100% - 80px);
  /* Adjust the maximum width to leave space for the button */
  overflow-x: auto;
  /* Enable horizontal scrolling */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.pagination-btn {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-num {
  color: white;
  font-weight: 600;
  padding: 3px;
}

.nav-link {
  color: white;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

.nav-link {
  background-color: #033E1F;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  margin-left: 2px;
}

.nav-tabs .nav-link.active {
  color: green !important;
  font-weight: 600;
  background-color: #f9b233;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link.hover {
  color: green !important;
  font-weight: 600;
  background-color: grey;
  border-color: #dee2e6 #dee2e6 #fff;
}

.carousel-container {
  overflow-x: auto;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carousel-arrow-left {
  left: 0;
}

.carousel-arrow-right {
  right: 0;
}

a:active {
  color: green;
}

.games-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Style for leaderboard section */
.leaderboard-container {
  margin-top: 20px;
}

/* Style for leaderboard list */
.leaderboard-list {
  list-style-type: none;
  padding: 0;
}

/* Style for leaderboard list item */
.leaderboard-item {
  margin-bottom: 5px;
}

/* Style for MSISDN, name, and bets */
.msisdn {
  font-weight: bold;
}

.name {
  margin-left: 5px;
}

.bets {
  margin-left: 5px;
}

/* Responsive styling */
@media only screen and (max-width: 768px) {

  /* Adjust font size for smaller screens */
  .msisdn,
  .name,
  .bets {
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {

  /* Adjust margins for smaller screens */
  .name,
  .bets {
    margin-left: 0;
    margin-top: 5px;
    /* Add space between items */
  }
}


@media (min-width: 768px) {
  .game-card {
    flex: 0 0 calc(33.33% - 5px);
    /* 4 games per row with 20px margin between them */
  }
}

@media (max-width: 767.98px) {
  .game-card {
    flex: 0 0 calc(33.33% - 5px);
    /* 3 games per row with 20px margin between them */
  }
}

.game-card {
  position: relative;
}

.game-details {
  width: 100%;
}

.game-details p {
  margin-bottom: 5px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.favorite-icon {
  position: absolute;
  top: 10px;
  /* Adjust top position as needed */
  right: 10px;
  /* Adjust right position as needed */
  font-size: 20px;
  /* Adjust font size as needed */
  color: red;
  /* Adjust color as needed */
  cursor: pointer;
}

.play-button {
  width: 100%;
  /* Fill the width */
  padding: 10px;
  /* Add padding as needed */
  background-color: #f9b233;
  /* Add desired background color */
  color: #033E1F;
  /* Add desired text color */
  font-weight: 600;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}


.game-card:hover {
  transform: translateY(-5px);
}

.filter-dropdown select {
  appearance: none;
  /* Remove default appearance */
  background-color: #fff;
  /* Background color */
  border: 1px solid #ccc;
  /* Border */
  border-radius: 5px;
  /* Border radius */
  padding: 8px 12px;
  /* Padding */
  font-size: 11px;
  /* Font size */
  font-weight: 600;
  /* Font weight */
  cursor: pointer;
  /* Cursor on hover */
}

/* Dropdown arrow */
.filter-dropdown select::after {
  content: "\25BC";
  /* Unicode character for down arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  /* Prevents the arrow from being clickable */
}

/* Hover effect */
.filter-dropdown select:hover {
  border-color: #aaa;
  /* Border color on hover */
}

/* Focus effect */
.filter-dropdown select:focus {
  outline: none;
  /* Remove outline */
  border-color: #555;
  /* Border color on focus */
}

.filter-dropdown select option {
  background-color: #fff;
  /* Background color */
  color: #333;
  /* Text color */
  font-size: 11px;
  /* Font size */
}

.filter-dropdown select:disabled {
  opacity: 0.5;
  /* Reduced opacity */
  cursor: not-allowed;
  /* Disabled cursor */
}

.search-bar {
  position: relative;
}

.search-bar input[type="text"] {
  width: calc(100% - 30px);
  /* Subtracting the total padding (15px on each side) */
  padding: 8px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 11px;
  outline: none;
}

.search-bar input[type="text"]:focus {
  border-color: #007bff;
}

.search-bar .search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #ccc;
  cursor: pointer;
}

.search-bar .search-icon:hover {
  color: #007bff;
}

.custom-input {
  width: 100%;
  /* Adjusted width to match the search icon */
  padding: 6px 12px;
  /* Adjusted padding to match the filter button */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  outline: none;
}

.custom-input:focus {
  border-color: #007bff;
}

.custom-input::placeholder {
  color: #666;
  /* Placeholder text color */
  font-style: italic;
  /* Italicize placeholder text */
}

.BottomFooter {
  margin-top: 20px;
  /* Adjust this value as needed */
}

.vue-carousel-slide {
  padding: 10px;
}

/* Adjustments for responsiveness */
@media (max-width: 767.98px) {
  .vue-carousel-slide {
    padding: 0 10px;
    /* Adjust padding for smaller screens */
  }
}

/* Button styling */
.nav-link {
  display: block;
  padding: 5px 10px;
  margin-bottom: 5px;
  color: white !important;
  cursor: pointer;
}

.nav-link.active {
  background-color: #f9b233;
  font-weight: 600;
  color: white !important;
}

.nav-link:hover {
  background-color: #fff;
  font-weight: 600;
  color: green !important;
}

.tab-navigation {
  display: flex;
  justify-content: space-around;
}

.tab-navigation button {
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
}

.tab-navigation button.active {
  background-color: green;
  color: white;
}

/* Adjustments for responsiveness */
@media (max-width: 767.98px) {
  .game-details {
    text-align: center;
    /* Center align the game details */
  }
}
</style>
<script>

import ShimmerCasino from "../shimmer/ShimmerCasino.vue";
import casino from "@/services/casino";
import VLazyImage from "v-lazy-image/v2";
import launch from "@/services/launch";
import Clarity from "@microsoft/clarity";

const Slider = () => import('../SliderCasino.vue');

export default {
  name: "Casino",
  components: {
    Slider,
    ShimmerCasino,
    VLazyImage
  },
  data: function () {
    return {
      placholder: '/img/placeholder.jpeg',
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      code: "",
      action_verify_password: false,
      action_success: false,
      message: "",
      allgamesdata: [],
      to_search: '',
      searchgamesdata: [],
      allgames: [],
      categories: [],
      casinoCategory: 602,
      casinoCategoryActive: {
        "id": 602,
        "name": "Crash Games"
      },
      casinoCategoryIndex: 0,
      disable: true,
      loading: false,
      currentPage: 1,
      perPage: 1000,
      homePagePopularGames: [],
    };
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "casino");

    let cat = this.$store.state.activeCasinoCategory

    if (cat.id === -1) {

      this.getCasinoGames(this.casinoCategoryActive);

    } else {

      this.getCasinoGames(cat);

    }

    this.reloadProfile();

    this.getCategories();

    this.showSlides();

  },
  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },
  methods: {

    getLaunchUrl: function (providerId, gameId, gameName, is_demo) {

      if (parseInt(is_demo) === 0 && !this.profile) {

        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;

      }

      var accountID = parseInt(process.env.VUE_APP_CASINO_ACCOUNT_ID);
      let payload = {};

      let isDemo = parseInt(is_demo)

      payload = {
        account_id: accountID,
        demo: isDemo,
        device: this.isDesktop() ? 'desktop' : 'mobile',
        game_id: String(gameId),
        game_name: gameName,
        return_url: window.location.origin + '/casino',
        lobby_url: window.location.origin + '/casino',
        provider_id: providerId,
      };

      console.log('provider ' + providerId + ' | payload ' + JSON.stringify(payload, undefined, 2))

      return this.launchGame(payload); // Return the launch URL


    },

    launchGame: function (payload) {

      var vm = this;

      var service;

      service = launch;

      this.loading = true;

      service
        .post("/launch/url", payload, {
          headers: {
            "api-key": vm.getAuth(),
          }
        })
        .then(response => {

          vm.loading = false;

          let eventName = "customer-game-open"

          if (payload.demo === 1) {

            eventName = "customer-demo-game-open"

          }

          Clarity.event(eventName);
          Clarity.setTag(eventName, `GameName: ${payload.game_name} (${payload.game_id}) | ${payload.provider_id}`);

          vm.launchURL = response.data.game_url;
          console.log("LaunchURL", vm.launchURL)

          //if (vm.launchType === "3") {

          window.open(this.launchURL);

          //}

          //console.log("LaunchUrl", vm.launchURL)
          //this.isLoaded = true; // Set isLoaded to true after getting the launch URL
          //return vm.launchURL;

        })
        .catch(error => {

          vm.loading = false;
          console.error("Error launching game:", error);
        });
    },

    getName: function (name) {

      if (name.length < 15) {

        return name

      }

      return name.substring(0, 15) + '...'

    },

    isActive: function (item) {
      // console.log(item)
      if (item.id == this.casinoCategoryActive.id) {
        return ' active';
      } else {
        return '';
      }
    },
    closeModal: function () {
      var docs = document.getElementsByClassName("modal-window")[0];
      docs.classList.add('d-none');
    },


    performSearch: function () {

      var keyword = this.to_search;
      this.searchCasinoGames(keyword);

    },

    loadPage: function (launch_url) {

      window.open(launch_url, '_blank');

    },

    getImg: function (img) {

      return img.replaceAll("/rec/325/", "/square/200/")

    },

    showSlides: function () {

      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {

        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {

        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {

        dots[i].className = dots[i].className.replace(" active", "");

      }

      if (slides[slideIndex - 1]) {

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";

      }

      setTimeout(function () {

        vm.showSlides();

      }, 2000)  // Change image every 2 seconds

    },

    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;

    },

    getKey: function (index) {

      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },

    getCasinoGames: function (activeCategory) {

      this.loading = true;
      this.casinoCategoryActive = activeCategory;
      this.searchgamesdata = []

      this.$store.dispatch("setActiveCasinoCategory", activeCategory)

      var vm = this;

      var path = "/games?page=" + this.currentPage + "&per_page=" + this.perPage + "&category_id=" + activeCategory.id;

      casino.get(path)
        .then((resp) => {

          vm.allgamesdata = resp.data.data;
          vm.allgames = vm.allgamesdata;

          this.loading = false;

        })
        .catch(err => {

          console.log(err.response)
          vm.loading = false;

        })

    },

    searchCasinoGames: function (search) {

      this.loading = true;

      var vm = this;

      var path = "/games?page=" + this.currentPage + "&per_page=" + this.perPage + "&name=" + search;

      casino.get(path)
        .then((resp) => {

          vm.loading = false;
          vm.searchgamesdata = resp.data.data;
          vm.allgamesdata = vm.searchgamesdata
          window.scrollTo(0, 0);

        })
        .catch(err => {

          console.log(err.response)
          vm.loading = false;

        })

    },

    getCategories: function () {

      var vm = this;

      var path = "/category";

      casino.get(path)
        .then(res => {

          vm.categories = res.data;

        })
        .catch(err => {

          if (err.response) {

            vm.setError("Failed", err.response.data.error_message)

          } else if (err.request) {

            console.log(JSON.stringify(err.request));

          } else {

            console.log(JSON.stringify(err));

          }
        })

    },

    reset: function () {

      this.searchgamesdata = [];
      this.to_search = "";
      this.allgamesdata = this.allgames

    },

    loadingClass: function () {

      return this.loading ? 'loading' : ''

    }

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>
<template>
    <div>
        <a @click="transfer"
            style="background-color: var(--darkBackground);border-radius: 0.5rem;color: var(--yellow) !important;padding: 0.3rem 0.5rem !important;border: solid var(--yellow) 1px;"
            class="py-2 form-control mt-2">
            Transfer to wallet
        </a>
    </div>
</template>

<script>
import affiliate from "@/services/affiliate";
export default {
    name: "SortDeposit",
    data: () => ({
        bonus_code: null,
        loading: false,
        message: null,
    }),
    components: {
        //
    },
    mounted() {
        window.$(this.$refs.transferModal).on('hidden.bs.modal', this.reset)
    },

    methods: {
        reset() {
            this.bonus_code = null;
            this.message = null;
            this.loading = false;
        },

        transfer() {
            console.log("Method hit");
            this.message = null;
            const authToken = this.getAuth();
            if (!authToken) {
                this.$toast.open({
                    message: 'Please login to proceed',
                    type: 'error',
                    position: 'top'
                });
                this.$router.push({ name: "login", params: {} });
                return;
            }

            // Use the environment variable for the base URL
            const baseUrl = process.env.VUE_APP_AFFILIATE_BASE_URL;
            const path = `${baseUrl}/wallet/transfer`;
            this.loading = true;

            affiliate
                .post(
                    path,
                    {}, // If any payload is needed, provide it here. Otherwise, an empty object is fine.
                    {
                        headers: {
                            "api-key": authToken,
                            "accept": "application/json"
                        },
                    }
                )
                .then((res) => {
                    if (res.status === 201) {
                        const msg = res.data.data;
                        this.message = {
                            message: msg,
                            type: 'success'
                        };
                        this.$toast.open({
                            message: msg,
                            type: 'success',
                            position: 'top'
                        });
                    } else {
                        this.message = {
                            message: 'Unexpected response from the server.',
                            type: 'error'
                        };
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        const errorCode = parseInt(err.response.data.error_code);
                        const errorMessage = err.response.data.error_message || 'An error occurred.';

                        switch (errorCode) {
                            case 400:
                                this.message = {
                                    message: errorMessage,
                                    type: 'error'
                                };
                                this.$toast.open({
                                    message: errorMessage,
                                    type: 'error',
                                    position: 'top'
                                });
                                break;
                            case 401:
                                this.$toast.open({
                                    message: 'Authorization error. Please log in again.',
                                    type: 'error',
                                    position: 'top'
                                });
                                this.$router.push({ name: "login" });
                                break;
                            case 403:
                                this.message = {
                                    message: 'Forbidden: User is not allowed to view approvals.',
                                    type: 'error'
                                };
                                break;
                            case 451:
                                this.message = {
                                    message: 'Account status error: Your account has been deactivated.',
                                    type: 'error'
                                };
                                break;
                            case 500:
                                this.message = {
                                    message: 'Internal server error. Please try again later.',
                                    type: 'error'
                                };
                                break;
                            default:
                                this.message = {
                                    message: errorMessage,
                                    type: 'error'
                                };
                        }
                    } else if (err.request) {
                        this.message = {
                            message: 'Network Error. Check your network connection and try again.',
                            type: 'error'
                        };
                    } else {
                        this.message = {
                            message: 'An error occurred. Please try again.',
                            type: 'error'
                        };
                    }
                })
                .finally(() => {
                    this.loading = false;
                });

        },

    }
}
</script>

<style scoped>
.join-button {
    background-color: var(--darkBackground);
    border-radius: 0.5rem;
    color: var(--white) !important;
    padding: 1rem 0.5rem !important;
}

.affiliate-button {
    background-color: var(--darkBackground);
    border-radius: 0.5rem;
    color: var(--white) !important;
    padding: 0.3rem 0.5rem !important;
}

.form,
.wallet-modal {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.transfer-modal {
    background: rgba(0, 0, 0, 0.5);
}

.alert {
    position: relative;
}

.wallet-modal {
    background: var(--primary);
    padding: 1rem;
    border-radius: 0.5rem;
}

.modal-dialog {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.modal-content {
    margin-top: auto;
    background: none;
}

.md-title {
    display: flex;
    flex-direction: column;
}

.md-title h4 {
    margin: 0;
}

.md-title span {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.5)
}
</style>
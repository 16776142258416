<template>
  <div class="iframe-container scroll no-scrollbar">
    <iframe allow="fullscreen" v-show="isLoaded" class="iframe-container1" v-bind:src="launchURL" allowfullscreen webkitallowfullscreen></iframe>
  </div>
</template>

<script>

import Clarity from '@microsoft/clarity';
import launch from "@/services/launch";
import walletserve from "@/services/walletserve";

export default {
  name: "CasinoGames",
  components: {
  },
  data: function () {
    return {
      loading: "",
      gameName: "",
      gameID: '',
      icon: "",
      isLoaded: false,
      launchURL: "",
      launchParameters: {},
      launchType: 1,
      isDemo: 0,
      amount: "",
    };

  },
  mounted() {

    this.$store.dispatch("setCurrentPage", "casinogames");
    this.reloadProfile();
    // this.getLaunchUrl();

  },
  computed: {

    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {

        // react to route changes...
        this.gameName = to.params.gameName;
        this.gameID = to.params.gameID;
        this.launchParameters = to.params.launchParameters;
        this.launchType = to.params.launchType;
        this.isDemo = to.params.IsDemo;

        let demoMode = "0"
        console.log('is demo '+to.params.IsDemo)

        if(to.params.IsDemo === 1) {

          demoMode = "1"

        }

        let payload = {
          provider_id: to.params.providerID,
          game_name: to.params.gameName,
          game_id: to.params.gameID,
          is_demo: demoMode,
        }

        if(parseInt(to.params.providerID) > 0 && to.params.gameID.length > 0 ) {

          console.log('setLaunchGamePayload ' + JSON.stringify(payload, undefined, 2))

          // either save in vuex
          this.$store.dispatch("setLaunchGamePayload", payload)

          // or save in session
          this.saveObject("launchGamePayload",payload)

        }

        this.getLaunchUrl()

      },
    },
  },
  methods: {

    deposit: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 10) {
        this.setError("Invalid Amount", "Enter amount atleast 10 KSH or above");
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          vm.setSuccess("Success", msg);
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (parseInt(err.response.data.error_code) === 401) {

              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );

              vm.logout();
              return;

            } else {

              vm.setError("Failed", err.response.data.error_message);
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   "Network Error",
            //   "Check your network connection and try again three"
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    getLaunchUrl: function() {

      // get launch details from vuex or sessions
      let data = this.$store.state.launchGamePayload
      //let data = this.getObject("launchGamePayload")

      console.log('Launch parameters ' + JSON.stringify(data, undefined, 2))

      if (parseInt(data.is_demo) === 0 && !this.profile) {

        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;

      }

      var providerId = data.provider_id;
      var gameId = data.game_id;
      var gameName = data.game_name;
      var accountID = parseInt(process.env.VUE_APP_CASINO_ACCOUNT_ID);
      let payload = {};

      let isDemo = parseInt(data.is_demo)

      payload = {
        account_id: accountID,
        demo: isDemo,
        device: this.isDesktop() ? 'desktop' : 'mobile',
        game_id: String(gameId),
        game_name: gameName,
        return_url: window.location.origin + '/casino',
        lobby_url: window.location.origin + '/casino',
        provider_id: providerId,
      };

      console.log('provider ' + providerId + ' | payload ' + JSON.stringify(payload, undefined, 2))

      return this.launchGame(payload); // Return the launch URL


    },

    launchGame: function (payload) {

      var vm = this;

      var service;

      service = launch;

      service
        .post("/launch/url", payload, {
          headers: {
            "api-key": vm.getAuth(),
          }
        })
        .then(response => {

          let eventName = "customer-game-open"

          if(payload.IsDemo === 1 ) {

            eventName = "customer-demo-game-open"

          }

          Clarity.event(eventName);
          Clarity.setTag(eventName, `GameName: ${payload.game_name} (${payload.game_id}) | ${payload.provider_id}`);

          vm.launchURL = response.data.game_url;
          console.log("LaunchURL", vm.launchURL)

          if (vm.launchType === "3") {

            window.open(this.launchURL);

          }

          console.log("LaunchUrl", vm.launchURL)
          this.isLoaded = true; // Set isLoaded to true after getting the launch URL
          return vm.launchURL;

        })
        .catch(error => {
          console.error("Error launching game:", error);
        });
    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },

  },
};
</script>
<template>
  <div style="background-color: #1b1e25" class="wallet-modal">
    <div class="md-title">
      <h4 class="text-light">Be An Affiliate</h4>
    </div>
    <section class="form">
      <div
        v-if="message"
        class="alert"
        v-bind:class="{
          'alert-success': message.type === 'success',
          'alert-danger': message.type === 'error',
        }"
        role="alert"
      >
        {{ message.message }}
      </div>
      <input
        style="
          width: 100%;
          height: 50px;
          border-radius: 5px;
          background-color: #23313d;
          text-align: center;
          color: white;
          border: solid 1px #1b1e25;
        "
        type="text"
        class="std-input"
        name="code"
        placeholder="Enter code"
        min="0"
        aria-label="Code"
        v-model="code"
      />
      <button
        @click="affiliate"
        v-bind:class="loading"
        class="form-control btn-std btn-prim btn-large"
        :disabled="loading"
      >
        {{ loading ? "Processing..." : "JOIN" }}
      </button>
    </section>
  </div>
</template>

<script>
import affiliate from "@/services/affiliate";

export default {
  name: "SortDeposit",
  data: () => ({
    code: null,
    loading: false,
    message: null,
    myProfile: null,
  }),
  components: {
    //
  },
  mounted() {
    window.$(this.$refs.redeemModal).on("hidden.bs.modal", this.reset);
    this.myProfile = this.getProfile();
    if (this.myProfile) {
      this.code = this.myProfile.referral_code;
    }
  },

  methods: {
    reset() {
      this.code = null;
      this.message = null;
      this.loading = false;
    },

    affiliate() {
      this.message = null;
      var p = this.getAuth();
      if (!p) {
        this.$toast.open({
          message: "Please login to proceed",
          type: "error",
          position: "top",
        });
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var vm = this;
      var path = "/join";

      var data = {
        code: this.code,
      };

      this.loading = true;

      affiliate
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          var msg = res.data.data;
          this.message = {
            message: msg,
            type: "success",
          };

          this.$router.push("/affiliates");
        })
        .catch((err) => {
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              this.$toast.open({
                message: "Your session on this device has expired",
                type: "error",
                position: "top",
              });
              // vm.logout();
              return;
            } else {
              console.log(err.response.data);
            }
          } else if (err.request) {
            this.message = {
              message:
                "Network Error. Check your network connection and try again",
              type: "error",
            };
          } else {
            this.message = {
              message: "An error occurred. Please try again",
              type: "error",
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.form,
.wallet-modal {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.affiliate-modal {
  background: rgba(0, 0, 0, 0.5);
}

.alert {
  position: relative;
}

.wallet-modal {
  background: var(--primary);
  padding: 1rem;
  border-radius: 0.5rem;
}

.modal-dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-content {
  margin-top: auto;
  background: none;
}

.md-title {
  display: flex;
  flex-direction: column;
}

.md-title h4 {
  margin: 0;
}

.md-title span {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
}
</style>

<template>
  <div>
    <section class="account-details px-0 py-0">
      <div class="row px-1 py-1 m-0 mb-2">
        <div
          class="row"
          style="
            width: 96%;
            height: 70px;
            background-color: #1b1e25;
            border-radius: 5px;
            margin-right: 2%;
            margin-left: 2%;
          "
        >
          <div style="margin: 5px" class="col">
            <p>My Phone:</p>
            <strong class="text-light">{{ profile.msisdn }}</strong>
          </div>
          <div style="margin: 5px" class="col">
            <p>Account Balance:</p>
            <strong class="text-light">Ksh. {{ bal }}</strong>
          </div>
        </div>

        <div
          class="row"
          style="
            width: 96%;
            min-height: 70px;
            background-color: #1b1e25;
            border-radius: 5px;
            margin-right: 2%;
            margin-left: 2%;
            margin-top: 10px;
          "
        >
          <div class="d-flex">
            <div style="margin: 5px" class="col">
              <p>Bonus Balance:</p>
              <strong class="text-light">Ksh. {{ bonus }}</strong>
            </div>

            <div style="margin: 5px; padding-left: 50px">
              <p>Withdrawable Balance:</p>
              <strong class="text-light">Ksh. {{ withdrawBal }}</strong>
            </div>
          </div>
          <!-- class="col " -->
          <div
            class="d-flex flex-column justify-content-center align-items-center mx-auto"
          >
            <div style="margin: 5px">
              <p></p>

              <div id="main-content">
                <button
                  style="
                    background-color: #febf10;
                    color: black;
                    border-radius: 3px;
                  "
                  data-toggle="modal"
                  data-target="#withdrawModal"
                  v-bind:class="loading"
                  class="btn"
                >
                  <span style="color: black">Withdraw</span>
                </button>
              </div>

              <div
                class="modal fade"
                id="withdrawModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="withdrawModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div
                    style="color: var(--buttonBackgroundColor)"
                    class="modal-content"
                  >
                    <div class="modal-header">
                      <h5 class="modal-title" id="withdrawModalLabel">
                        Withdraw
                      </h5>
                      <span
                        style="color: var(--buttonBackgroundColor)"
                        type="button"
                        class="close"
                        id="faso-close"
                        data-dismiss="modal"
                        >&times;</span
                      >
                    </div>

                    <div class="modal-body">
                      <div class="form-group">
                        <label for="withdraw_amount">Amount</label>
                        <input
                          v-model="withdraw_amount"
                          type="number"
                          class="form-control"
                          id="withdraw_amount"
                          placeholder="Enter amount to withdraw"
                          style="background-color: #23313d; color: white"
                        />
                        <br />
                        <strong class="text-blue mb-3 mt-3"
                          >Minimum Ksh. 100 Maximum Ksh. 70,000</strong
                        >
                        <br />
                        <button
                          style="
                            background-color: #febf10;
                            color: black;
                            border-radius: 3px;
                          "
                          @click="withdraw"
                          v-bind:class="loading"
                          class="btn btn-block"
                        >
                          <span style="color: black">Withdraw</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <hr class="m-1" />
    <div class="text-yellow text-center matches-title mb-1">Deposit <br /></div>
    <section class="px-2 mb-3">
      <div class="form-wrapper mb-0">
        <router-link
          to="/deposit"
          class="join-button py-2 form-control"
          style="color: black !important"
          >DEPOSIT TO ACCOUNT</router-link
        >
      </div>
    </section>

    <hr class="m-1" />

    <div class="text-yellow text-center matches-title mb-1">
      Refer A friend and Earn <br />
      <span class="text-blue"
        >Many gifts awaiting for you if you share the below link with your
        friends</span
      >
    </div>

    <section class="px-2 mb-3">
      <div
        class="social-icons d-flex justify-content-around px-2 text-center mb-2"
      >
        <a
          href="https://www.facebook.com/SparkpesaKE/"
          target="_blank"
          title="Tweet"
          onclick="window.open('https://www.facebook.com/SparkpesaKE/' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
        >
          <img loading="lazy" src="img/facebook.png" /><br />
          <span class="text-blue small-text"> Facebook</span>
        </a>

        <a
          href="https://instagram.com/sparkpesa?utm_medium=copy_link&t="
          title="Share on Facebook"
          onclick="window.open('https://instagram.com/sparkpesa?utm_medium=copy_link' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&t=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
          target="_blank"
        >
          <img loading="lazy" src="img/instagram.png" /> <br />
          <span class="text-blue small-text"> Instagram</span>
        </a>

        <!-- <a
          data-action="share/whatsapp/share"
          target="_blank"
          onclick="window.open('whatsapp://send?text=' + document.getElementById('share-bet-msg').textContent); return false;"
        >
          <img loading="lazy" src="img/whatsapp.png" /><br />
          <span class="text-blue small-text"> Whatsapp</span>
        </a> -->
        <!-- <a
          target="_blank"
          onclick="window.open('https://api.whatsapp.com/send?phone=254791618714&text=' + encodeURIComponent(referralMessage)); return false;"
        >
          <img loading="lazy" src="img/whatsapp.png" /><br />
          <span class="text-blue small-text">WhatsApp</span>
        </a> -->

        <a
          target="_blank"
          :href="whatsappLink"
          onclick="return !window.open(this.href, 'WhatsApp', 'width=550,height=450')"
        >
          <img loading="lazy" src="img/whatsapp.png" /><br />
          <span class="text-blue small-text">WhatsApp</span>
        </a>

        <a
          href="https://twitter.com/SparkpesaKe?s=11"
          target="_blank"
          title="Tweet"
          onclick="window.open('https://twitter.com/sparkpesa?s=11text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
        >
          <img loading="lazy" src="img/twitter.png" /><br />
          <span class="text-blue small-text"> Twitter</span>
        </a>

        <!-- <a
          data-action="share/telegram/share"
          target="_blank"
          onclick="window.open('https://telegram.me/share/url?url=' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
        >
          <img loading="lazy" src="img/telegram.png" /><br />
          <span class="text-blue small-text"> Telegram</span>
        </a> -->
        <!-- <a
          href="#"
          target="_blank"
          onclick="window.open('https://telegram.me/share/url?url=' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
        >
          <img loading="lazy" src="img/telegram.png" /><br />
          <span class="text-blue small-text">Telegram</span>
        </a> -->

        <a
          target="_blank"
          :href="telegramLink"
          onclick="return !window.open(this.href, 'Telegram', 'width=550,height=450')"
        >
          <img loading="lazy" src="img/telegram.png" /><br />
          <span class="text-blue small-text">Telegram</span>
        </a>

        <a
          v-bind:href="getSMSContent"
          target="_blank"
          class="d-flex flex-column"
        >
          <img loading="lazy" src="/img/sms.png" />
          <span class="text-blue small-text">SMS</span>
        </a>
      </div>

      <div v-if="showAffiliateDashboard" class="row p-0 m-0 px-2 mb-2">
        <div class="col-12 px-1">
          <router-link to="/affiliates">
            <div
              id="share-bet-link"
              class="share-betslip-btn"
              style="color: #ffffff; text-align: center"
            >
              Go to Dashboard
            </div>
          </router-link>
        </div>

        <div
          class="d-flex mx-auto justify-content-center"
          style="width: 100%; margin-top: 10px"
        >
          <div>
            <input class="form-control text-white" :value="referralLink" />
          </div>
          <div>
            <button
              class="btn btn-warning ml-1"
              @click="copyToClipboard(referralLink)"
            >
              {{ copyText }}
            </button>
          </div>
        </div>
      </div>

      <div v-else>
        <Affiliate />
      </div>
    </section>

    <hr class="m-1" />

    <div class="text-center slip-ttxt py-3">
      <div class="text-blue mb-3">
        Want to logout? We'd like you to stay though
      </div>
      <div>
        <a @click="logout" class="login-button py-2 px-4">Logout</a>
      </div>
    </div>
  </div>
</template>

<script>
import wallet from "@/services/wallet";
import axios from "@/services/api";
import Affiliate from "./Affiliate.vue";

export default {
  name: "Setting",
  components: {
    Affiliate,
  },
  data: function () {
    return {
      amount: 0,
      loading: "",
      withdraw_amount: "",
      inputBackground: "#23313D",
      new_referral_code: "",
      errors: [],
      code: "",
      link: "",
      msg: "",
      copyText: "Copy",
      copyBookingCode: "Click to copy",
      myProfile: this.getProfile(),
      showAffiliateDashboard: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        // react to route changes...
        document.title = "My Sparkpesa Account";
        document.description = "Manage Sparkpesa account";
      },
    },
    new_referral_code: function (n) {
      console.log("got new value here " + n);

      this.validateReferralLink();
    },

    withdraw_amount(newValue) {
      if (newValue.trim() !== "") {
        this.inputBackground = "white";
      } else {
        this.inputBackground = "#23313D";
      }
    },
  },
  mounted() {
    this.fetchAffiliateStatus();

    var vm = this;

    this.$store.dispatch("setCurrentPage", "my-account");
    vm.myProfile = vm.getProfile();

    if (vm.myProfile.id === undefined) {
      this.setError("Login", "Please login to proceed");
      this.$router.push({ name: "login", params: { action: "login" } });
      return;
    }

    this.code = vm.myProfile.referral_code;
    this.link = process.env.VUE_APP_REFERERRAL_LINK + this.code;

    this.msg =
      "Hey!! Nabet na Sparkpesa. Join me to be a champion, click this link to signup >> \n\n\n\n " +
      this.link;

    const modal = document.getElementById("withdrawModal");

    modal.addEventListener("show.bs.modal", () => {
      const overlay = document.createElement("div");
      overlay.className = "black-background";
      overlay.id = "modal-overlay";
      document.body.appendChild(overlay);
    });

    modal.addEventListener("hidden.bs.modal", () => {
      const overlay = document.getElementById("modal-overlay");
      if (overlay) {
        overlay.remove();
      }
    });
  },
  methods: {
    copyToClipboard(text) {
      if (!text) {
        this.copyText = "No Link to Copy";
        return;
      }

      navigator.clipboard.writeText(text).then(
        () => {
          this.copyText = "Copied!";
          setTimeout(() => (this.copyText = "Copy"), 2000);
        },
        (err) => {
          console.error("Failed to copy: ", err);
          this.copyText = "Error!";
        }
      );
    },

    async fetchAffiliateStatus() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_AFFILIATE_BASE_URL}/whoami`,
          {
            headers: { "api-key": this.getAuth() },
          }
        );

        this.showAffiliateDashboard = response.status === 200;
      } catch (error) {
        console.error("Error fetching affiliate status:", error);
        this.showAffiliateDashboard = false;
      }
    },

    setAmount: function (amount) {
      this.amount = amount;
    },
    deposit: function () {
      this.reset();
      var p = this.getProfile();
      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: { action: "login" } });
        return;
      }

      if (this.amount < 1) {
        this.setError("Invalid Amount", "ENter amount atleast 1 KSH or above");
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      vm.loading = "loading";

      wallet
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          console.log(res);
          vm.setSuccess(
            "Deposit Initiated",
            "Enter your PIN to complete the transaction"
          );
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (parseInt(err.response.status) === 401) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.error_message);
              console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            console.log(JSON.stringify(err.request));
          } else {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            console.log(JSON.stringify(err));
          }
        });
    },
    validateReferralLink: function () {
      this.errors = [];

      if (this.new_referral_code.length < 4) {
        this.errors.push("Referral code should be more than 3 characters");
      }

      if (this.new_referral_code.length > 20) {
        this.errors.push("Referral code should be less than 21 characters");
      }

      if (!/^[0-9a-zA-Z]+$/.test(this.new_referral_code)) {
        this.errors.push(
          "Referral code can only contain letters A-Z and numbers 0 - 9"
        );
      }
    },
    withdraw: function () {
      this.reset();
      var p = this.getProfile();
      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: { action: "login" } });
        return;
      }

      if (this.withdraw_amount < 100) {
        this.setError(
          "Invalid Amount",
          "Enter amount atleast 100 KSH or above"
        );
        return;
      }

      var vm = this;
      var path = "/withdraw";

      var data = {
        amount: parseInt(this.withdraw_amount),
      };

      vm.loading = "loading";

      wallet
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";

          console.log(JSON.stringify(res.data, undefined, 2));

          var msgs = res.data.data;
          vm.setSuccess("Withdrawal Initiated", msgs);
        })
        .catch((err) => {
          vm.loading = "";

          if (err.response) {
            console.log(JSON.stringify(err.response, undefined, 2));

            if (parseInt(err.response.status) === 401) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              //vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.error_message);
              console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            console.log(JSON.stringify(err.request));
          } else {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            console.log(JSON.stringify(err));
          }
        });
    },
    shareInvite: function () {
      document.getElementById("open-shareinvite").click();
    },
    copyShareBetLink: function () {
      var link = this.link;
      this.copyToClipboard(link);
      this.copyText = "Copied";
    },
    copyCode: function () {
      var link = "accept#" + this.code;
      this.copyToClipboard(link);
      this.copyBookingCode = "Invite Code Copied";
    },
  },
  computed: {
    bonus: function () {
      return this.formatCurrency(this.$store.state.bonusBalance);
    },
    getSMSContent: function () {
      const shareUrl = `https://sparkpesa.com/?referal=${this.code}`;
      const shareText = `Lets win big on Sparkpesa, mimi hubet na Sparkpesa, trustable and legit, click on this link to join me and get 500Ksh bonus   \n\n${shareUrl}`;
      return `sms:?&body=${shareText}`;
    },
    referralLink() {
      return `https://sparkpesa.com/?referral=${this.code}`;
    },
    referralMessage() {
      return `Join Sparkpesa and use my referral code to sign up: ${this.code}`;
    },
    whatsappLink() {
      return `https://api.whatsapp.com/send?phone=${
        this.profile.msisdn
      }&text=${encodeURIComponent(this.referralMessage)}`;
    },
    telegramLink() {
      return `https://t.me/share/url?url=${encodeURIComponent(
        this.referralLink
      )}&text=${encodeURIComponent(this.referralMessage)}`;
    },
    bal: function () {
      return this.formatCurrency(this.$store.state.walletBalance);
    },
    withdrawBal: function () {
      return this.formatCurrency(this.$store.state.withdrawableBalance);
    },
    profile: function () {
      return this.$store.state.profile;
    },
    has_referral_code: function () {
      return this.code !== undefined && this.code.length > 3;
    },
  },
  filters: {
    currency: function (amount) {
      if (amount == null) {
        amount = 0;
      }

      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "KES",
      });
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }

      return parseFloat(x).toFixed(2);
    },
  },
};
</script>

<style>
.copy-div {
  background-color: #23313d;
  width: 100%;
  border-radius: 3px;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Center content vertically */
  margin-left: auto;
  margin-right: auto;
  padding: 4px;
}

.modal-content {
  background-color: #1b1e25;
}

.modal-body {
  background-color: #1b1e25;
}

.black-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
  z-index: 1040;
  /* Ensure it's below the modal but above other content */
}

.blurred-background {
  filter: blur(5px);
  transition: filter 0.3s ease-in-out;
}

.modal-blur {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  /* Safari */
}
</style>
